<template>

  <div id="overlay" />

  <Navigation />

  <main id="page">
    <Dynamic :parts="content" />
  </main>

</template>

<script>

import Navigation from '@/components/structure/Navigation.vue';
import Dynamic from '@/components/structure/Dynamic.vue';
import dynamicPage from '@/hooks/dynamicPage';

export default {
  components: {

    Navigation,
    Dynamic,

  },

  setup() {
    dynamicPage();
  },

  watch: {
    slug() {
      if (this.slug !== 'home') {
        document.body.classList.add('bg-red');
      } else {
        document.body.classList.remove('bg-red');
      }
    },
  },

  computed: {
    content() {
      return this.$store.state.router.page?.content;
    },
    slug() {
      return this.$store.state.router.page?.meta?.slug;
    },
  },

};
</script>
