<template>
  <div class="hamburger relative">
    <div class="bar" />
    <div class="bar" />
    <div class="bar" />
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.hamburger {
  cursor: pointer;
  box-sizing: content-box;
  width: px(35);
  padding: px(15);
  margin: px(-15);
  z-index: 100;

  &:hover, &.active {
    .bar {
      background: var(--tuerkis);
    }
  }
}

.bar {
  height: px(3);
  background-color: currentColor;
  transition: all 0.5s;

  &:nth-child(1) {
    width: 100%;
    margin-bottom: px(10);
    .active & {
      transform: translateY(calc(0.813rem)) rotate(45deg);
    }
  }

  &:nth-child(2) {
    width: 100%;
    transform: translateX(0);
    margin-bottom: px(10);
    .active & {
      opacity: 0;
      transform: translateX(1.25rem) !important;
    }
  }

  &:nth-child(3) {
    width: 100%;
    .active & {
      transform: translateY(calc(-0.813rem)) rotate(-45deg);
    }
  }

}
</style>
